define("ember-content-editable-modifier/modifiers/content-editable", ["exports", "ember-modifier", "@ember/object"], function (_exports, _emberModifier, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  /* eslint-disable prettier/prettier */
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let ContentEditableModifier = (_class = class ContentEditableModifier extends _emberModifier.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "value", undefined);
    }
    didInstall() {
      if (this.args.named.placeholder) {
        this.element.setAttribute('placeholder', this.args.named.placeholder);
      }
      this.element.classList.add('ember-content-editable');
      this.addEventListener();
      this.updateValue();
      if (this.args.named.autofocus) {
        this.element.focus();
      }
    }
    didReceiveArguments() {
      if (this.args.named.disabled && this.element.getAttribute('contenteditable')) {
        this.element.removeAttribute('contenteditable');
      } else if (!this.args.named.disabled && !this.element.getAttribute('contenteditable')) {
        this.element.setAttribute('contenteditable', 'true');
      }
    }
    didUpdateArguments() {
      if (this.value != this.args.named.value) {
        this.updateValue();
      }
    }
    updateValue() {
      this.value = this.args.named.value;
      if (this.value) {
        this.element.innerText = this.value;
      } else {
        this.element.innerText = '';
      }
    }
    willDestroy() {
      this.removeEventListener();
    }
    domUpdated() {
      this.value = this.element.innerText;
      if (this.args.named.onChange) {
        this.args.named.onChange(this.value);
      }
    }
    addEventListener() {
      this.element.addEventListener('input', this.domUpdated);
    }
    removeEventListener() {
      this.element.removeEventListener('input', this.domUpdated);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "domUpdated", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "domUpdated"), _class.prototype), _class);
  _exports.default = ContentEditableModifier;
});